// spacing for last child
.m-first-0:first-child,
.m-last-0:last-child {
  margin: 0 !important;
}
.p-first-0:first-child,
.p-last-0:last-child {
  padding: 0 !important;
}

// border
.border-first-0:first-child,
.border-last-0:last-child {
  border: 0 !important;
}

.section {
  padding: 30px 0;

  &--grey {
    background-color: #f6f6f6;
  }

  &__title {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 8px;
  }

  &__desc {
    text-align: center;
    margin-bottom: 35px;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 26px;
    }
  }
}

.button {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: $white;
  padding: 0 55px;
  line-height: 35px;
  border-radius: 18px;
  border: 0;
  background: $primary;

  &:hover {
    color: $white;
    text-decoration: none;
    background: darken($primary, 10%);
  }
}

.breadcrumb-item {
  a {
    color: $black;
  }
}

.breadcrumb-bar {
  background-color: #f6f6f6;

  &__inner {
    display: flex;
    align-items: center;
    padding: 16px 0;
  }

  .breadcrumb {
    max-width: 625px;
  }

  @media (max-width: 767px) {
    &__inner {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

.social-nav {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  margin-left: auto;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  &__link {
    color: #999999;
    font-size: 18px;

    .fa-facebook {
      color: $color-facebook;
    }

    .fa-twitter {
      color: $color-twitter;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    margin-left: 0;

    &__link {
      font-size: 16px;
    }
  }
}

.contact {
  &__map {
    height: 265px;
    margin-top: 20px;
  }
}

.stars {
  &::before {
    content: "\f005 \f005 \f005 \f005 \f005";
    font-family: "fontawesome";
    letter-spacing: 4px;
    color: #ffc120 !important;
  }

  &--0::before {
    content: "\f006 \f006 \f006 \f006 \f006";
  }

  &--0-5::before {
    content: "\f123 \f006 \f006 \f006 \f006";
  }

  &--1::before {
    content: "\f005 \f006 \f006 \f006 \f006";
  }

  &--1-5::before {
    content: "\f005 \f123 \f006 \f006 \f006";
  }

  &--2::before {
    content: "\f005 \f005 \f006 \f006 \f006";
  }

  &--2-5::before {
    content: "\f005 \f005 \f123 \f006 \f006";
  }

  &--3::before {
    content: "\f005 \f005 \f005 \f006 \f006";
  }

  &--3-5::before {
    content: "\f005 \f005 \f005 \f123 \f006";
  }

  &--4::before {
    content: "\f005 \f005 \f005 \f005 \f006";
  }

  &--4-5::before {
    content: "\f005 \f005 \f005 \f005 \f123";
  }

  &--5::before {
    content: "\f005 \f005 \f005 \f005 \f005";
  }
}

body.rtl {
  .social-nav {
    padding-right: 0;
    margin-left: 0;
    margin-right: auto;

    &__item {
      &:first-child {
        margin-right: 0;
      }

      &:not(:last-child) {
        margin-left: 40px;
      }
    }

    @media (max-width: 767px) {
      margin-right: 0;
    }
  }

  .breadcrumb {
    .breadcrumb-item + .breadcrumb-item {
      padding-left: 0;
      padding-right: 8px;

      &::before {
        padding-right: 0;
        padding-left: 8px;
      }
    }
  }
}

.sticky-btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 100;

  &__icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    @include shadow-sm;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__text {
    padding: 2px 10px;
    background-color: $white;
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    right: 55px;
    transform: translateY(-50%);
    white-space: nowrap;
    border-radius: 8px;
    filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.23));

    &::after {
      content: "";
      border: 6px solid transparent;
      border-left-color: $white;
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translatey(-50%);
      margin-left: -1px;
    }
  }
}

body.rtl .sticky-btn {
  right: auto;
  left: 30px;

  &__text {
    right: auto;
    left: 55px;

    &::after {
      margin-left: 0;
      margin-right: -1px;
      left: auto;
      right: 100%;
      border-color: transparent $white transparent transparent;
    }
  }
}
