.header {
  height: 90px;
  position: relative;
  z-index: 100;

  &__wrapper {
    width: 100%;
    background-color: rgba(#fff, 0.98);
    transition: 0.3s;
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: inline-flex;
    margin-right: auto;

    img {
      display: block;
      height: 90px;
      transition: 0.3s;
    }
  }

  &__hot-btn {
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    font-size: 16px;
    line-height: 35px;
    border-radius: 18px;
    padding: 0 20px;
    border: 0;
    background-color: $primary;
    margin-right: 10px;

    &:hover {
      background-color: darken($primary, 10%);
      text-decoration: none;
      color: $white;
    }
  }

  @media (min-width: 1200px) {
    height: 126px;

    &__inner {
      position: relative;
      transition: 0.3s linear;
    }

    &__logo {
      img {
        height: 115px;
      }
    }

    &__btn {
      display: none;
    }

    &__hot-btn {
      display: none;
      // margin: 0;
      // position: absolute;
      // bottom: 38px;
      // right: 0;
      // transform: translateY(50%);
      // transition: 0.3s linear;
    }
  }
}

.menu-root {
  & > .menu-item {
    & > .menu-link {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  @media (min-width: 1200px) {
    & > .menu-item {
      & > .menu-link {
        line-height: 75px;
        transition: 0.3s;
      }

      &:hover > .menu-link {
        color: $primary;
      }

      &.menu-item-group > .menu-link {
        padding-right: 20px;
      }

      &.menu-item-group > .menu-toggle {
        display: none;
      }
    }

    .menu-sub {
      min-width: 246px;
      background-color: rgba($primary, 0.8);
      padding: 0 16px;
      border-radius: 0;

      .menu-item {
        margin: 0;

        &:not(:last-child) {
          border-bottom: 1px dotted $border-color;
        }
      }

      .menu-link {
        color: $white;
        line-height: 50px;
        padding: 0 12px;
      }

      .menu-item:hover > .menu-link {
        background: 0;
      }

      .menu-toggle {
        display: none;
      }
    }
  }
}

@media (min-width: 1200px) {
  .header.scrolled .menu-root > .menu-item > .menu-link {
    line-height: 56px;
  }
}

.h-search {
  padding: 0 20px 20px;
  margin-bottom: 16px;
  border-bottom: 1px solid $border-color;
  transition: 0.3s linear;

  &__toggle {
    display: none;
  }

  @media (min-width: 1200px) {
    padding: 0;
    margin: 0;
    border-bottom: 0;
    position: absolute;
    z-index: 300;
    top: -25px;
    right: 0;
    transform: translateY(-50%);

    &__inner {
      display: none;
      width: 400px;
      position: absolute;
      top: -2px;
      right: -8px;
    }

    &__toggle {
      display: inline-flex;
      width: 25px;
      height: 25px;
      justify-content: center;
      align-items: center;
      color: $white;
      background-color: $black;
      border-radius: 50%;
    }

    .input-group {
      border-radius: 15px;
      box-shadow: 0 1px 2px rgba(#000, 0.3);

      .form-control {
        height: 30px;
        border-radius: 15px 0 0 15px;
        font-size: 13px;
        border: 0;
        padding: 0 20px;
      }

      .input-group-text {
        border-radius: 0 15px 15px 0;
        color: $black;
        background-color: $white;
        border-color: $white;
      }
    }
  }
}

.h-nav {
  padding-top: 16px;
  border-top: 1px solid $border-color;
  transition: 0.3s linear;

  & > .menu-item {
    margin: 0;
  }

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 200;
    top: -25px;
    right: 40px;
    transform: translateY(-50%);

    & > .menu-item {
      &:not(:last-child) {
        position: relative;

        &::after {
          content: '';
          display: block;
          height: 12px;
          border-right: 1px solid $white;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      & > .menu-toggle {
        display: none;
      }

      &.menu-item-group > .menu-link::after {
        content: '\f107';
        display: inline-block;
        font-family: 'fontawesome';
        margin-left: 4px;
      }

      &.menu-item-group:hover > .menu-sub {
        display: block;
        animation: menu-float-show 0.2s ease-out;
      }

      .menu-sub {
        min-width: 160px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        position: absolute;
        z-index: 2;
        top: -4px;
        left: calc(100% + 4px);
        border-radius: 4px;
        transform-origin: 0 4px;
        background-color: $white;
        &::before {
          content: '';
          display: block;
          width: 8px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 100%;
        }
        &.menu-sub-right {
          left: auto;
          right: calc(100% + 4px);
          transform-origin: 100% 0;
          &::before {
            right: auto;
            left: 100%;
          }
        }
      }

      .menu-sub .menu-link {
        padding-left: 20px;
      }

      &.menu-item-group > .menu-sub {
        transform-origin: 0 0;
        top: calc(100% + 4px);
        left: 0;
        &::before {
          width: 100%;
          height: 8px;
          right: auto;
          left: 0;
          top: auto;
          bottom: 100%;
        }
      }
    }
  }
}

.h-social {
  display: flex;
  list-style: none;
  margin-top: 20px;
  padding: 0 20px;
  margin-bottom: 40px;
  transition: 0.3s linear;

  &__item {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__link {
    color: inherit;

    &:hover {
      color: inherit;
    }

    .fa {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border: 1px solid;
      border-radius: 50%;
      color: $black;
      font-size: 12px;
    }
  }

  @media (min-width: 1200px) {
    margin: 0;
    padding: 0;
    position: absolute;
    top: -25px;
    left: 0;
    transform: translateY(-50%);

    &__link {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.navbar-hotbtn {
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  color: $white;
  font-size: 16px;
  line-height: 35px;
  border-radius: 18px;
  padding: 0 20px;
  border: 0;
  background-color: $primary;
  margin-left: 10px;

  &:hover {
    background-color: darken($primary, 10%);
    text-decoration: none;
    color: $white;
  }

  @media (max-width: 1199px) {
    display: none;
    margin-left: 10px;
  }
}

.header.scrolled .header__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 1px 2px rgba(#000, 0.15);
}

@media (min-width: 1200px) {
  .header.scrolled .header__logo {
    img {
      height: 90px;
    }
  }

  .header.scrolled .h-search,
  .header.scrolled .h-nav,
  .header.scrolled .h-social {
    top: -20px;
  }

  .header.scrolled .navbar.navbar-mobile {
    margin-top: 40px;
  }

  .header.scrolled .navbar.navbar-mobile {
    margin-top: 40px;
  }

  .header.scrolled .menu-root > .menu-item > .menu-link {
    line-height: 50px;
  }
}

body.rtl {
  .header {
    &__logo {
      margin-left: auto;
      margin-right: 0;
    }

    &__hot-btn {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  .h-nav {
    .menu-item {
      .menu-link {
        .fa {
          margin-right: 0 !important;
          margin-left: 8px;
        }
      }

      &-group > .menu-link::after {
        margin-left: 0;
        margin-right: 4px;
      }
    }

    @media (min-width: 1200px) {
      right: auto;
      left: 40px;

      .menu-sub {
        right: 0;
        transform-origin: top right !important;
      }
    }
  }

  .navbar-hotbtn {
    @media (min-width: 1200px) {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  .h-social {
    &__item {
      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 10px;
      }
    }

    @media (min-width: 1200px) {
      left: auto;
      right: 0;
    }
  }

  .h-search {
    .form-control {
      border-radius: 0 4px 4px 0;
    }

    .input-group-text {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }

    @media (min-width: 1200px) {
      right: auto;
      left: 0;

      &__inner {
        right: auto;
        left: -8px;
      }

      .input-group {
        overflow: hidden;
      }
    }
  }
}
