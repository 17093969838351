.tour {
  min-height: 100%;
  position: relative;
  z-index: 1;

  &__link {
    @include img-shine;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 15;
    top: 0;
    left: 0;
  }

  &__iwrap {
    @include iwrap(62.5%);
    min-height: 100%;
    overflow: hidden;

    img {
      transition: 0.4s;
    }
  }

  &:hover &__iwrap img {
    transform: scale(1.2) rotate(3deg);
  }

  &__body {
    display: flex;
    align-items: flex-start;
    padding: 40px 20px 10px;
    background-image: linear-gradient(to bottom, transparent, rgba(#000, 0.6));
    position: absolute;
    z-index: 11;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__title {
    @include max-line(2);
    flex: 1;
    font-size: 18px;
    font-weight: 700;
    color: $white;
    margin-bottom: 0;
  }

  &__price {
    font-size: 18px;
    font-weight: 700;
    color: $danger;
    margin-left: 16px;
  }

  @media (max-width: 575px) {
    &__body {
      padding: 30px 12px 8px;
      flex-direction: column-reverse;
    }

    &__title {
      font-size: 12px;
    }

    &__price {
      font-size: 12px;
      margin-left: 0;
    }
  }
}

.tour-2 {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &__link {
    @include img-shine;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 15;
    top: 0;
    left: 0;
  }

  &__iwrap {
    @include iwrap(60%);
  }

  &__date {
    width: 50%;
    min-width: 200px;
    font-size: 18px;
    font-weight: 700;
    color: $danger;
    background-color: $white;
    text-align: center;
    position: absolute;
    top: 34px;
    right: 34px;
    z-index: 10;
    transform-origin: 50% 50%;
    transform: translate(50%, -50%) rotateZ(45deg);
  }

  &__title {
    margin-bottom: 0;
    padding: 30px 20px 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
    font-size: 18px;
    font-weight: 900;
    color: $white;
    background-image: linear-gradient(to bottom, transparent, rgba(#000, 0.5));
  }

  @media (max-width: 575px) {
    &__date {
      font-size: 16px;
      top: 20px;
      right: 20px;
    }

    &__title {
      font-size: 16px;
      padding: 30px 12px 8px;
    }
  }
}

.tour-3 {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $danger;
    font-size: 16px;
    line-height: 1;
    margin-right: 12px;

    span:first-child {
      font-size: 26px;
      font-weight: 700;
    }
  }

  &__route {
    color: $danger;

    span {
      display: inline-block;

      &::after {
        content: '\f178';
        font-family: 'fontawesome';
        margin: 0 8px;
      }

      &:last-child::after {
        display: none;
      }
    }
  }

  &__desc {
    @include max-line(3);
    margin-bottom: 10px;
  }

  &__price {
    font-size: 22px;
    font-weight: 700;
    color: $danger;
  }

  &__oldprice {
    font-size: 18px;
    margin-right: 16px;
    color: #999;
    font-weight: 400;
  }

  &__map {
    color: #999;

    &:hover {
      color: $primary;
    }
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    margin-top: auto;
  }

  &__icons {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    margin: -5px;
    margin-right: auto;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #000;
      margin: 5px;
      cursor: pointer;
      opacity: 0.5;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  @media (max-width: 1199px) {
    &__icons {
      margin-bottom: 0;
    }

    &__footer {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media (max-width: 575px) {
    &__title {
      margin-bottom: 8px;
      font-size: 16px;
    }

    &__desc {
      margin-bottom: 8px;
    }
  }
}

.tour-5 {
  background-color: #f6f6f6;
  padding-right: 20px;
  border: 1px solid rgba($primary, 0.5);

  &__name {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
    font-weight: 700;
    background-color: rgba($primary, 0.15);
    padding: 20px;
  }

  &__info {
    font-size: 16px;
    padding: 20px 0;
  }

  &__price {
    font-size: 20px;
    font-weight: 700;
    color: $primary;
  }

  &__price-number {
    font-size: 2em;
    margin-left: 0.5em;
  }

  @media (max-width: 767px) {
    padding: 0 20px;

    &__name {
      margin: 0 -20px;
      width: calc(100% + 40px);
    }
  }
}

.tour-6 {
  display: flex;
  flex-direction: column;
  height: 320px;
  min-height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: rgba(#000, 0.6);
    transition: 0.3s;
  }

  &:hover::before {
    background-color: rgba(#000, 0);
  }

  &__bg {
    width: 100%;
    height: 100%;
    z-index: -2;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 20px;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: $white;
  }

  &__desc {
    @include max-line(2);
    text-align: center;
    color: $white;
    margin: 4px 0 16px;
  }

  &__collapse {
    display: none;
  }

  &__btnwrap {
    text-align: center;
  }

  &__state {
    text-align: center;
    color: $danger;
    font-size: 18px;
    font-weight: 900;
    text-shadow: 1px 1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
      -1px -1px 0 #fff;
    transition: 0.3s;
    margin-top: 4px;
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    height: 250px;

    &__title {
      font-size: 22px;
    }

    &__content {
      padding: 8px;
    }

    &__state {
      font-size: 16px;
    }
  }
}

.service {
  position: relative;

  &__iwrap {
    @include iwrap(67%);
    @include img-shine;
  }

  &__title {
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(#000, 0.3);

    &:hover {
      background-color: rgba(#000, 0.6);
    }

    a {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: $white;
    }

    a:hover {
      color: $white;
    }
  }
}

.news {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  @include shadow;

  &__iwrap {
    @include iwrap(56.25%);
    @include img-shine;

    img {
      transition: 0.4s;
    }

    &:hover img {
      transform: scale(1.2) rotate(3deg);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 50px 30px 40px;
    position: relative;
    z-index: 10;
  }

  &__view {
    padding: 0 6px;
    position: absolute;
    top: -28px;
    left: 4px;
    z-index: 10;
    color: $white;
    font-weight: 700;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.2);
      filter: blur(10px);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.2;

    a:hover {
      color: $primary;
    }
  }

  &__desc {
    @include max-line(4);
    text-align: center;
    margin-bottom: 40px;
  }

  &__btn {
    border-radius: 18px;
    border-color: $primary;
    font-weight: 700;
    color: $primary;
  }

  @media (max-width: 767px) {
    &__body {
      padding: 30px 20px 20px;
    }

    &__title {
      font-size: 16px;
    }
  }
}

.news-2 {
  &__iwrap {
    @include iwrap(60%);
    @include hover-zoom;
    width: 140px;
    margin-right: 12px;
  }

  &__title {
    @include max-line(4);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;

    a:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}

.pros {
  &__iwrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    img {
      height: 55px;
    }
  }

  &__title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__desc {
    text-align: center;
  }
}

.m-contact {
  background-color: $black;
  color: $white;
  padding: 25px 0;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 25px;
    font-weight: 700;
  }

  &__btn {
    display: inline-block;
    font-size: 23px;
    line-height: 60px;
    padding: 0 60px;
    border-radius: 30px;
    border: 0;
    background-color: $white;
    color: $danger;
    font-weight: 700;
    text-transform: uppercase;
    transition: 0.3s;

    &:hover {
      text-decoration: none;
      color: $white;
      background-color: $danger;
    }
  }

  &__icon {
    display: inline-flex;
    margin: 0 20px;
    height: 38px;
  }

  &__note {
    display: flex;
    align-items: center;
    margin-left: auto;

    img {
      height: 28px;
      margin-right: 20px;
    }
  }

  @media (max-width: 1199px) {
    &__inner {
      flex-direction: column;
    }

    &__title {
      text-align: center;
      font-size: 18px;
    }

    &__icon {
      height: 20px;
    }

    &__btn {
      margin: 20px 0;
      line-height: 50px;
      font-size: 20px;
    }

    &__note {
      margin-left: 0;
      text-align: center;

      .fa {
        font-size: 16px;
      }
    }
  }
}

.banner {
  position: relative;
  z-index: 1;
  padding-top: 155px;

  &__bg {
    position: absolute;
    z-index: -2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &__inner {
    display: flex;
    height: 400px;
    align-items: center;
    justify-content: center;
  }

  &__title {
    text-align: center;
    margin-bottom: 0;
    font-size: 35px;
    font-weight: 700;
    color: $white;
  }

  @media (max-width: 1199px) {
    padding-top: 115px;

    &__inner {
      height: 250px;
    }

    &__title {
      font-size: 24px;
    }
  }
}

.gallery {
  @include iwrap(60%);
  @include hover-zoom;
}

.video {
  &__iwrap {
    @include iwrap(56.25%);
  }

  &__overlay {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: rgba(#000, 0.3);
    }

    .fa {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border: 2px solid $white;
      border-radius: 50%;
      color: $white;
      padding-left: 4px;
      font-size: 20px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin: 8px 0;
    text-align: center;
  }
}

.videos {
  &__title {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 35px;
  }
}

.video-slider {
  position: relative;

  &__prev,
  &__next {
    display: flex;
    padding: 10px 5px;
    background-color: #f6f6f6;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    cursor: pointer;
    color: $success;
    font-size: 30px;
    transform: translateY(-50%);
    margin-top: -20px;

    &:focus {
      outline: 0;
    }

    .fa {
      transform: unset !important;
    }
  }

  &__next {
    left: auto;
    right: 0;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;

    &:hover {
      background-color: #f6f6f6;
    }

    img {
      height: 74px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}

.preview {
  position: relative;
  z-index: 1;

  &__frame {
    img {
      min-height: 250px;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
}

.preview-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    display: flex;
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 10;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      width: 30px;
    }

    &:focus {
      outline: 0;
    }

    .fa {
      transform: unset !important;
    }
  }

  &__next {
    left: auto;
    right: 20px;
  }
}

.thumb-slider {
  width: 600px;
  max-width: 100%;
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);

  &__iwrap {
    @include iwrap(60%);
    border: 2px solid $white;
    cursor: pointer;
  }

  @media (max-width: 575px) {
    bottom: 10px;
    padding: 0 10px;
  }
}

body.rtl {
  .news {
    &__view {
      left: auto;
      right: 4px;

      .fa {
        margin-right: 0 !important;
        margin-left: 8px;
      }
    }
  }

  .news-2 {
    &__iwrap {
      margin-right: 0;
      margin-left: 12px;
    }
  }

  .tour-3 {
    &__date {
      margin-right: 0;
      margin-left: 12px;
    }

    &__route {
      span {
        &::after {
          content: '\f177';
        }

        &:first-child::after {
          display: none;
        }

        &:last-child::after {
          display: inline;
        }
      }
    }

    &__icons {
      padding-right: 0;
      margin-right: 0;
      margin-left: auto;
      padding-left: 8px;
    }

    &__map {
      .fa.mr-2 {
        margin-right: 0 !important;
        margin-left: 8px;
      }
    }
  }

  .tour-5 {
    padding-right: 0;
    padding-left: 20px;

    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }
}
