body {
  font-family: $lato;

  &.rtl {
    direction: rtl;
    text-align: right;

    .ml-2 {
      margin-left: 0 !important;
      margin-right: 8px;
    }

    .mr-2 {
      margin-right: 0 !important;
      margin-left: 8px;
    }

    .ml-3 {
      margin-left: 0 !important;
      margin-right: 16px;
    }

    .mr-3 {
      margin-right: 0 !important;
      margin-left: 16px;
    }

    .fa-long-arrow-left,
    .fa-long-arrow-right,
    .fa-angle-left,
    .fa-angle-right,
    .fa-phone {
      transform: rotateY(180deg);
    }

    .list-unstyled {
      padding-right: 0;
    }
  }
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
}

a,
button,
input,
textarea,
select {
  &:focus {
    outline: 0;
  }
}

p:last-child {
  margin-bottom: 0;
}
