.news-intro {
  padding-top: 20px;

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  &__desc {
    margin-bottom: 40px;
  }
}

.aside {
  border: 1px solid $border-color;
  margin-bottom: 30px;

  &__title {
    border-bottom: 1px solid $border-color;
    font-weight: 700;
    text-align: center;
    font-size: 25px;
    line-height: 34px;
    padding: 10px;
    margin-bottom: 0;
  }

  &__list {
    list-style: none;
    margin-bottom: 0;
    padding: 12px;
  }

  &__item {
    &:not(:last-child) {
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid $border-color;
    }
  }
}

.post {
  &-title {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &-time {
    color: #999;
    margin-bottom: 10px;
  }

  &-map {
    border: 1px solid $border-color;
    padding: 16px;
    margin-bottom: 30px;

    ul {
      list-style: none;
      padding-left: 0;

      li {
        ul {
          list-style: none;
          padding-left: 10px;
        }

        a {
          color: $gray-800;
        }
      }
    }
  }

  &-map-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

body.rtl {
  .post-map ul {
    padding-right: 0;

    ul {
      padding-right: 10px;
    }
  }
}
