.tour-intro {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  &__img {
    width: 300px;
    margin-left: 30px;
  }

  @media (max-width: 767px) {
    &__img {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.tour-summary {
  td {
    &:nth-child(1) {
      white-space: nowrap;
      color: $primary;
    }

    &:nth-child(2) {
      min-width: 150px;
    }

    &:nth-child(3) {
      white-space: nowrap;
    }

    &:nth-child(4) {
      min-width: 350px;
    }
  }
}

.tour-plan-switch {
  display: inline-block;
  font-size: 14px;
  color: $primary;
  background-color: #f1f1f1;
  font-weight: 700;
  padding: 10px 20px;

  &:hover {
    color: $primary;
    background-color: #eee;
  }
}

.transfers {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  li {
    padding: 4px 6px;
  }

  a {
    color: inherit;
    opacity: 0.5;

    &:hover {
      color: inherit;
      text-decoration: none;
      opacity: 1;
    }
  }

  img {
    height: 18px;
  }
}

.tour-section {
  padding: 20px 0 0;

  &__title {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    line-height: 1.2;
  }

  &__desc {
    margin-bottom: 80px;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 30px;
    }
  }
}

.tour-post {
  padding-top: 50px;
  margin-bottom: 80px;

  &-title {
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &-content {
    margin-bottom: 30px;
  }
}

.tour-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    position: absolute;
    z-index: 20;
    top: 50%;
    left: -20px;
    font-size: 30px;
    color: $success;
    transform: translateY(-50%);
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    .fa {
      transform: unset !important;
    }
  }

  &__next {
    left: auto;
    right: -20px;
  }

  @media (max-width: 575px) {
    &__prev {
      left: -12px;
    }

    &__next {
      right: -12px;
    }
  }
}

.tour-form {
  padding: 10px 20px 20px;
  border: 1px solid $border-color;

  &__title {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__captcha {
    .form-control {
      border-radius: 0;
    }

    .input-group-text {
      border: 0;
      background: 0;
      font-size: 16px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__btn {
    display: inline-block;
    color: $white;
    background-color: $primary;
    font-size: 14px;
    line-height: 45px;
    padding: 0 25px;
    text-transform: uppercase;
    font-weight: 700;
    border: 0;
  }

  .form-control {
    border-radius: 0;
  }

  @media (max-width: 1440px) {
    .form-group {
      margin-bottom: 10px;

      label {
        font-size: 13px;
        margin-bottom: 4px;
      }
    }

    select.form-control,
    input.form-control {
      height: 30px;
      padding: 0 8px;
    }
  }
}

.tour-tabs {
  display: block;
  white-space: nowrap;
  overflow: auto;
  border-bottom: 1px solid $border-color;
  margin-bottom: 30px;

  .nav-item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  .nav-link {
    display: inline-flex;
    align-items: center;
    color: $black;
    padding: 10px 0;

    &:hover {
      color: $black;
      text-decoration: none;
    }

    &.active {
      box-shadow: inset 0 -4px 0 $success;
    }

    img {
      height: 16px;
      margin-right: 6px;
    }
  }
}

.list-included {
  list-style: none;
  padding-left: 0;

  li {
    padding-left: 30px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &::before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background: url('../images/icon-check.png') center/contain no-repeat;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
}

.list-excluded {
  list-style: none;
  padding-left: 0;

  li {
    padding-left: 30px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &::before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background: url('../images/icon-times.png') center/contain no-repeat;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
}

.tour-plan {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    height: 100%;
    border-right: 2px solid $primary;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 39px;
  }

  &__item {
    padding-left: 105px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  &__number {
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $primary;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 30px;
    color: $white;
    font-weight: 700;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px;
      border: 1px dashed $white;
      border-radius: 50%;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }
}

.date-alert {
  background-color: #f6f6f6;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}

.date-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary;
  color: $white;
  padding: 10px 20px;
  margin-bottom: 20px;

  &__title {
    font-size: 18px;
    font-weight: 700;
  }
}

.date-info {
  &__content {
    background-color: rgba($primary, 0.3);
    padding: 16px;
  }

  &__title {
    font-size: 20px;
    margin-bottom: 16px;
  }
}

.date-tabs {
  justify-content: flex-end;

  .nav-item {
    &:not(:last-child) {
      margin-right: 2px;
    }
  }

  .nav-link {
    color: $white;
    background-color: rgba($primary, 0.6);

    &.active {
      background-color: $primary;
    }
  }
}

.date-slider {
  background-color: #f6f6f6;
  padding: 0 40px;
  position: relative;

  .swiper-container {
    border-top: 1px solid rgba($primary, 0.3);
  }

  .swiper-slide {
    border-right: 1px solid rgba($primary, 0.3);
    border-bottom: 1px solid rgba($primary, 0.3);
  }

  .swiper-slide-thumb-active {
    background-color: $white;
    border-bottom-color: rgba($primary, 0.3);
  }

  &__prev,
  &__next {
    display: flex;
    width: 40px;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba($primary, 0.5);
    cursor: pointer;
    color: $white;
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 0;

    .fa {
      transform: unset !important;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: rgba($primary, 0.8);
      border: 0;
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 0;
  }

  &__item {
    padding: 10px 0;
    cursor: pointer;
  }

  .swiper-slide-thumb-active &__item {
    background-color: rgba($primary, 0.3);
  }

  &__date {
    font-size: 14px;
    text-align: center;
  }

  &__price {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    color: $primary;
    padding: 5px 0;
  }

  &__state {
    text-align: center;
    font-size: 14px;
    font-style: italic;
    color: #666;
  }
}

.date-table {
  &__tbody {
  }

  &__tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 40px;
    grid-template-rows: auto;
    border-top: 0;

    &:nth-child(even) {
      background-color: #f6f6f6;
    }
  }

  &__td {
    padding: 8px 12px;
    font-size: 16px;
    transition: 0.3s;
  }

  &__toggle {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $primary;
    cursor: pointer;

    .fa-minus-circle {
      display: none;
    }
  }

  &__expand {
    grid-column: span 6;
    background-color: rgba($primary, 0.3);
    padding: 16px 16px 0;
  }

  &__tfoot {
    border: 1px solid $border-color;
    border-top: 0;
  }

  &__more {
    display: block;
    padding: 8px;
    color: $primary;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    background-color: rgba($primary, 0.3);

    &:hover {
      color: $primary;
      text-decoration: none;
      background-color: rgba($primary, 0.5);
    }
  }

  &__tr.active &__td {
    background-color: $primary;
    color: $white;

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      span,
      div {
        display: none;
      }
    }
  }

  &__tr.active &__toggle {
    color: $white;

    .fa-plus-circle {
      display: none;
    }

    .fa-minus-circle {
      display: inline-block;
    }
  }

  @media (max-width: 1199px) {
    &__tr {
      grid-template-columns: 1fr 1fr 60px;
    }

    &__td {
      grid-column: span 1;

      &:nth-child(1) {
        grid-column: 1 / span 1;
      }

      &:nth-child(2) {
        grid-column: 2 / span 1;
      }

      &:nth-child(3) {
        grid-column: 3 / span 1;
      }

      &:nth-child(4) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
      }

      &:nth-child(5) {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
      }

      &:nth-child(6) {
        grid-column: 3 / span 1;
        grid-row: 2 / span 1;
      }
    }

    &__toggle {
      display: inline-block;
      width: auto;
      height: auto;
    }
  }
}

.tour-hotel-title {
  border-bottom: 1px solid $border-color;
  padding: 10px 0;
  margin-bottom: 20px;
  font-size: 18px;
}

.hotel {
  position: relative;
  overflow: hidden;

  &__position {
    color: $white;
    font-size: 13px;
    font-weight: 700;
    position: absolute;
    z-index: 10;
    top: 5px;
    left: 8px;
    text-shadow: 0 0 2px #000;
  }

  &__expand {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    color: $white;
    font-size: 10px;
    font-weight: 700;
    position: absolute;
    z-index: 10;
    top: 5px;
    right: 8px;
    text-shadow: 0 0 2px #000;
    background-color: rgba(#000, 0.5);
  }

  &:hover &__expand {
    background-color: rgba(#fff, 0.2);
  }

  &__iwrap {
    @include iwrap(60%);
  }

  &__gallery {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__body {
    background-color: $white;
    border: 1px solid $border-color;
    border-top: 0;
    position: relative;
    z-index: 10;
  }

  &__header {
    width: 100%;
    background-color: rgba(#000, 0.6);
    color: $white;
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 6px 8px;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
  }

  &__stars {
    font-size: 12px;
  }

  &__feature {
    padding: 8px;
    font-size: 12px;
    color: #666;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  &__label {
    text-transform: uppercase;
  }

  &__tripadvisor {
    display: inline-flex;
    align-items: center;
  }
}

.tour-header {
  padding: 20px 0;
  border-bottom: 1px solid $border-color;

  &__title {
    color: $primary;
    font-size: 26px;
    margin: 0;
    font-weight: 700;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }

  &__right {
    text-align: right;
  }

  &__price {
    del {
      color: $primary;
      font-size: 20px;
      line-height: 33px;
      font-weight: 700;
    }

    span {
      color: $danger;
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  &__date {
    font-size: 14px;
    color: #999;
    margin-right: 10px;

    span:first-child {
      font-size: 22px;
      color: $danger;
    }
  }

  &__destinations {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding-left: 16px;
    position: relative;

    &::before {
      content: '\f041';
      font-family: 'fontawesome';
      display: block;
      color: #999;
      position: absolute;
      top: 0;
      left: 0;
    }

    li {
      &:not(:last-child)::after {
        content: '→';
        display: inline-block;
        margin: 0 4px;
        color: #999;
      }
    }

    a {
      color: #999;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: 1px dotted $border-color;
    padding: 12px 0;
  }

  &__actions {
    display: flex;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    flex-wrap: wrap;

    li {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    a {
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #666;
    }

    img {
      width: 18px;
      height: 18px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}

body.rtl {
  .tour-header {
    &__date {
      margin-right: 0;
      margin-left: 10px;
    }

    &__destinations {
      padding-right: 16px;
      padding-left: 0;

      &::before {
        left: auto;
        right: 0;
      }

      li:not(:last-child)::after {
        content: '←';
      }
    }

    &__style {
      display: flex;
      margin-right: 0 !important;

      span:first-child {
        margin-left: 10px;
      }
    }

    &__actions {
      padding-right: 0;

      li:not(:last-child) {
        margin-right: 0;
        margin-left: 8px;
      }
    }

    &__price {
      display: flex;
    }
  }

  .tour-tabs {
    padding-right: 0;

    .nav-item {
      &:not(:last-child) {
        margin-right: 0;
        margin-left: 30px;
      }
    }

    .nav-link {
      img {
        margin-right: 0;
        margin-left: 6px;
      }
    }
  }

  .transfers {
    padding-right: 0;
  }

  .tour-plan-switch {
    .fa.ml-2 {
      margin-right: 8px;
      margin-left: 0 !important;
      transform: rotateY(180deg);
    }
  }

  .tour-plan {
    padding-right: 0;

    &::before {
      left: auto;
      right: 39px;
    }

    &__item {
      padding-left: 0;
      padding-right: 105px;
    }

    &__number {
      left: auto;
      right: 0;
    }
  }

  .tour-hotel-title {
    span {
      display: inline-block;
    }
  }
}
