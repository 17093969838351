.footer {
  background-color: #f6f6f6;

  &__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}

.f-menu {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    color: $gray-800;

    &:hover {
      color: $primary;
    }
  }
}

.f-contact {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    padding-left: 30px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    & > .fa {
      position: absolute;
      top: 4px;
      left: 0;
    }
  }
}

.f-social {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding-left: 30px;
  margin-top: 16px;

  &__item {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__link {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }

    .fa {
      display: flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid;
      color: $black;
      font-size: 10px;
    }

    &:hover {
      .fa {
        color: $white;
      }
      .fa-facebook {
        background-color: $color-facebook;
        border-color: $color-facebook;
      }
      .fa-twitter {
        background-color: $color-twitter;
        border-color: $color-twitter;
      }
      .fa-envelope-o {
        background-color: $color-google-plus;
        border-color: $color-google-plus;
      }
      .fa-youtube-play {
        background-color: $color-youtube;
        border-color: $color-youtube;
      }
    }
  }
}

.f-signup {
  max-width: 400px;

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
  }

  &__desc {
    margin-bottom: 5px;
  }

  .input-group {
    .form-control {
      height: 40px;
    }

    .input-group-text {
      width: 55px;
      justify-content: center;
      background-color: $success;
      color: $white;
    }
  }
}

body.rtl {
  .f-contact {
    padding-right: 0;

    &__item {
      padding-left: 0;
      padding-right: 30px;

      & > .fa {
        left: auto;
        right: 0;
      }
    }
  }

  .f-social {
    padding: 0 30px 0 0;

    &__item {
      &:not(:last-child) {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }

  .f-menu {
    padding-right: 0;
  }

  .f-hotline {
    span {
      display: inline-block;
    }
  }

  .f-signup {
    .form-control {
      border-radius: 0 4px 4px 0;
      border-left: 0;
    }

    .input-group-text {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }
  }
}
