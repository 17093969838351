.grid {
  display: flex;
  margin: 0 -15px;
  width: calc(100% + 30px);
  flex-wrap: wrap;
  margin-bottom: 30px;

  &__item {
    height: 242px;
    flex: 1;
    transition: 0.5s ease-in-out;
    overflow: hidden;
    position: relative;

    &.active {
      flex: 2;
    }
  }

  &__content {
    width: 866.67px;
    transition: 0.3s linear;
    padding: 0 15px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  @media (max-width: 1199px) {
    margin-bottom: 0;

    &__item {
      flex: 0 0 50% !important;
      max-width: 50% !important;
      height: unset;
      margin-bottom: 30px;
    }

    &__content {
      width: 100%;
      position: static;
      height: auto;
    }
  }

  @media (max-width: 575px) {
    &__item {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
}

.home-banner {
  height: 600px;

  @media (max-width: 991px) and (orientation: landscape) {
    height: 100vh;
  }

  @media (max-width: 991px) and (orientation: portrait) {
    height: 100vh;
  }
}

.testimonials {
  overflow: hidden;

  &__title {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 35px;
  }
}

.testimonial-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    cursor: pointer;
    color: $success;
    font-size: 30px;
    transform: translateY(-50%);

    &:focus {
      outline: 0;
    }

    .fa {
      transform: unset !important;
    }
  }

  &__next {
    left: auto;
    right: 0;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;

    &:hover {
      background-color: #f6f6f6;
    }

    img {
      height: 74px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}

.testimonial {
  &__text {
    max-width: 700px;
    margin: 0 auto 20px;
    text-align: center;
  }

  &__avatar {
    @include iwrap(100%);
    @include shadow;
    width: 100px;
    margin: 0 auto 10px;
    border-radius: 50%;
    border: 1px solid $white;
    overflow: hidden;
  }

  &__name {
    font-size: 16px;
    text-align: center;
    font-weight: 700;
  }

  &__job {
    text-align: center;
  }
}

.partners {
  overflow: hidden;

  &__title {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 35px;
  }
}

.partner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    cursor: pointer;
    color: $success;
    font-size: 30px;
    transform: translateY(-50%);

    &:focus {
      outline: 0;
    }

    .fa {
      transform: unset !important;
    }
  }

  &__next {
    left: auto;
    right: 0;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;

    &:hover {
      background-color: #f6f6f6;
    }

    img {
      height: 74px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}

.search {
  background-color: #f6f6f6;
  padding: 24px 0 0;

  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 16px;

    a {
      text-decoration: underline;
      font-size: 16px;
      font-weight: 700;
      text-shadow: 0 1px 1px rgba(#000, 0.3);
    }
  }

  .form-group {
    margin-bottom: 24px;
  }

  .form-control {
    border-radius: 0;
  }

  .input-group {
    .form-control {
      border-right-color: transparent;
    }

    .input-group-text {
      border-radius: 0;
      border-left-color: transparent !important;
      background-color: $white;
    }

    .form-control:focus + .input-group-append .input-group-text {
      border-color: rgba($primary, 0.5);
    }
  }

  .btn {
    border-radius: 0;
    font-weight: 700;
  }
}

.destination-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 16px;
  grid-auto-flow: row dense;

  &__item {
    &:nth-child(1) {
      grid-column: 1 / span 2;
      grid-row: 1 / span 1;

      .tour-6__iwrap::before {
        padding-bottom: 0;
      }
    }

    &:nth-child(6) {
      grid-column: 2 / span 2;
      grid-row: span 1;

      .tour-6__iwrap::before {
        padding-bottom: 0;
      }
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;

    &__item {
      &:nth-child(1),
      &:nth-child(6) {
        grid-column: 1 / span 2;

        .tour-6__iwrap::before {
          padding-bottom: 50%;
        }
      }
    }
  }
}

body.rtl {
  .grid {
    &__content {
      left: auto;
      right: 0;
    }
  }

  .btn {
    .fa-long-arrow-right.ml-2 {
      margin-left: 0 !important;
      margin-right: 8px;
      transform: rotateY(180deg);
    }
  }
}
