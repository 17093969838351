.order {
  padding: 0 40px 40px;
  &__heading {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 0;
    padding: 20px 0;
    text-align: center;
  }
  @media (max-width: 767px) {
    padding: 0 15px 20px;
  }
}
.order-info {
  text-align: center;
  padding-top: 15px;
  &__item {
    flex-grow: 1;
    padding: 15px;
  }
  &__label {
    color: #999;
    margin-bottom: 5px;
  }
  &__value {
    font-size: 20px;
    font-weight: bold;
  }
}
.order-detail {
  padding: 0 40px 10px;
  &__heading {
    font-size: 14px;
    padding: 20px 0;
    margin-bottom: 0;
    text-align: center;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }
}
.order-table {
  td {
    min-width: 150px;
  }
}
